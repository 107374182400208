import styled, { createGlobalStyle } from "styled-components"
import { Container, Carousel } from "react-bootstrap"

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: Karla, sans-serif;
    font-weight: 400;
    color: #16222f;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    padding-top: 96px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: Rubik, sans-serif;
    font-weight: 700;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const ProductsHeader = styled(Container)`
  margin: 50px auto 30px;

  h1,
  h2 {
    text-align: center;
  }

  h1 {
    font-size: 1.75rem;
    text-transform: uppercase;
    background: -webkit-linear-gradient(to right, #2c2962, #eee);
    background: linear-gradient(to right, #2c2962, #eee);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-size: 1.15rem;
    color: #595959;
    font-family: Karla, sans-serif;
    font-weight: normal;
    margin-bottom: 50px;
  }
`

export const ProductInfo = styled.div`
  text-align: center;
  margin-bottom: 30px;

  p {
    color: #595959;
    margin: 5px auto;
  }
`

export const StyledCarousel = styled(Carousel)`
  background-color: #f6f6f6;

  .carousel-inner {
    height: 400px;
    display: flex;
    align-items: center;
  }
`

export const CardPrice = styled.p`
  font-family: Rubik, sans-serif;
  font-weight: 700;
  text-align: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f6f6f6;
  border-radius: 3px;
  padding: 3px;
`
