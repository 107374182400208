import * as React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  background-color: #e8f4f8;

  position: relative;

  .custom-shape-divider-bottom-1627070679 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1627070679 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-1627070679 .shape-fill {
    fill: #ffffff;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const HeroContent = styled.div`
  max-width: 400px;

  h2 {
    font-size: 1rem;
    color: #575757;
  }

  h1 {
    font-size: 1.5rem;
    color: #2a2a2b;
    margin: 15px auto;
  }

  @media (max-width: 425px) {
    h1 {
      font-size: 1.25rem;
    }
  }
`

const HeroImg = styled.div`
  max-width: 500px;
`

const StyledButton = styled.a`
  background: #2c2962;
  background: -webkit-linear-gradient(to right, #2c2962, #363795);
  background: linear-gradient(to right, #2c2962, #363795);
  border-color: #2c2962;

  svg {
    fill: #ffffff;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const Hero = () => (
  <Wrapper>
    <div className="custom-shape-divider-bottom-1627070679">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
          className="shape-fill"
        ></path>
      </svg>
    </div>

    <StyledContainer>
      <HeroContent>
        <h2>Representante de Fábrica</h2>
        <h1>
          A JVM Aço oferece a você e sua empresa soluções em Armários,
          Roupeiros, Prateleiras e diversas opções em Aço com preço de fábrica,
          melhor prazo de entrega da região e atendimento especializado!
        </h1>
        <StyledButton
          className="btn btn-primary"
          href="https://wa.me/5519982386762"
        >
          <Whatsapp className="mx-1" size={16} />
          Entre em contato conosco!
        </StyledButton>
      </HeroContent>

      <HeroImg>
        <StaticImage
          src="../images/produtos.png"
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Estante de aço"
        />
      </HeroImg>
    </StyledContainer>
  </Wrapper>
)

export default Hero
