// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-armarios-2-portas-js": () => import("./../../../src/pages/armarios2portas.js" /* webpackChunkName: "component---src-pages-armarios-2-portas-js" */),
  "component---src-pages-arquivos-js": () => import("./../../../src/pages/arquivos.js" /* webpackChunkName: "component---src-pages-arquivos-js" */),
  "component---src-pages-estantes-js": () => import("./../../../src/pages/estantes.js" /* webpackChunkName: "component---src-pages-estantes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-roupeiros-js": () => import("./../../../src/pages/roupeiros.js" /* webpackChunkName: "component---src-pages-roupeiros-js" */)
}

