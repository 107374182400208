import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp"

const StyledNavbar = styled(Navbar)`
  background: #2c2962;
  background: -webkit-linear-gradient(to right, #2c2962, #363795);
  background: linear-gradient(to right, #2c2962, #363795);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);

  .navbar-collapse {
    justify-content: flex-end;
  }
`

const StyledNav = styled(Nav)``

const StyledNavBtn = styled(Nav)`
  a {
    background-color: rgb(37, 211, 102);
    border-radius: 3px;
    color: #16222f !important;
    text-align: center;

    svg {
      fill: #16222f;
    }
  }
`

const Header = ({ siteTitle }) => (
  <StyledNavbar variant="dark" expand="lg" className="fixed-top">
    <Container>
      <Navbar.Brand as={Link} to="/">
        <StaticImage
          src="../images/logo.png"
          height={70}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt={siteTitle}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <StyledNav>
          <Nav.Link className="mx-3" as={Link} to="/">
            Início
          </Nav.Link>
          <Nav.Link className="mx-3" as={Link} to="#products">
            Produtos
          </Nav.Link>
          <Nav.Link className="mx-3" as={Link} to="#contact">
            Contato
          </Nav.Link>
        </StyledNav>

        <StyledNavBtn>
          <Nav.Link href="https://wa.me/5519982386762">
            WhatsApp <Whatsapp className="mx-1" size={23} />
          </Nav.Link>
        </StyledNavBtn>
      </Navbar.Collapse>
    </Container>
  </StyledNavbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
