import * as React from "react"
import { Nav } from "react-bootstrap"
import { Link } from "gatsby"
import styled from "styled-components"

const NavWrapper = styled.nav`
  .nav {
    justify-content: center;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #2c2962;
  }

  .nav-pills .nav-link {
    background-color: #ededed;
    margin: 5px;
  }

  .nav-link {
    color: #2c2962;
  }
`

const NavItems = () => (
  <NavWrapper>
    <Nav variant="pills" defaultActiveKey="/#products">
      <Nav.Item>
        <Nav.Link as={Link} to="/#products" eventKey="/#products">
          Estantes
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/armarios2portas#products"
          eventKey="/armarios2portas#products"
        >
          Armários 2 Portas
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/arquivos#products"
          eventKey="/arquivos#products"
        >
          Arquivos
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/roupeiros#products"
          eventKey="/roupeiros#products"
        >
          Roupeiros
        </Nav.Link>
      </Nav.Item>
    </Nav>
  </NavWrapper>
)

export default NavItems
