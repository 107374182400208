import * as React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"

const FooterWrapper = styled.footer`
  background: #2c2962;
  background: -webkit-linear-gradient(to right, #2c2962, #363795);
  background: linear-gradient(to right, #2c2962, #363795);
  padding: 50px 0 20px;
  margin: 75px auto 0;

  h4,
  p {
    color: #ffffff;
  }

  h4 {
    font-size: 1.25rem;
  }

  a {
    margin: 5px;
  }

  svg {
    fill: #ffffff;
  }
`

const FooterContainer = styled(Container)`
  margin-bottom: 50px;
`

const StyledCol = styled(Col)`
  margin-bottom: 25px;
`

const FooterInfo = styled(Container)`
  text-align: center;

  hr {
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
  }
`

const Footer = () => (
  <FooterWrapper id="contact">
    <FooterContainer>
      <Row>
        <StyledCol sm="4" xs="12">
          <h4>Telefone / WhatsApp</h4>

          <p>(19) 98238.6762</p>

          <p>
            <a
              href="https://www.facebook.com/jvmacohortolandia/"
              aria-label="Facebook"
            >
              <FacebookCircle size="30" />
            </a>

            <a
              href="https://www.instagram.com/jvmacohortolandia/"
              aria-label="Instagram"
            >
              <Instagram size="30" />
            </a>
          </p>
        </StyledCol>

        <StyledCol sm="4" xs="12">
          <h4>Email</h4>

          <p>jvmacorepresentacoes@gmail.com</p>
        </StyledCol>

        <StyledCol sm="4" xs="12">
          <h4>Endereço</h4>

          <p>
            R. Argolino de Morães, 135, - Vila São Francisco, Hortolândia - SP,
            CEP: 13186-410
          </p>

          <p>
            Horário de funcionamento: Segunda a Sexta das 12 às 17h. Finais de
            semana e feriados com horário agendado.
          </p>
        </StyledCol>
      </Row>
    </FooterContainer>

    <FooterInfo>
      <hr />
      <p>
        © JVM Aço {new Date().getFullYear()}
        {` `}
        Desenvolvimento <a href="https://mzndigital.com.br">MZN Digital</a>
      </p>
    </FooterInfo>
  </FooterWrapper>
)

export default Footer
